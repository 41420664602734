
import { createActions, createReducer } from "reduxsauce";

const { Types, Creators } = createActions({
  requestEvents: [],
  successfulEvents: ["payload"],
  errorEvents: ["error"],
});

export const EventsActionCreators = Creators;

const initialState = {
  loading: false,
  events: [],
  error: "",
  selectedEvent: {},
  searchText: "",
  message: "",
  categories: [],
};

const requestEvents = (state = initialState) => ({
  ...state,
  loading: true,
  error: "",
  events: [],
});

const  sortFunc = (a,b) => {
  const { catA } = a.categories && a.categories[0].name;
  const { catB } = b.categories[0].name;
  if(a.name < b.name ){
    return -1;
  }
  if(a.name  > b.name ){
    return 1;
  }
  return 0

}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  let ar = [];
  map.forEach((value, key, map) => {
    ar = ar.concat(value)
  })
  return ar;
}

const successfulEvents = (state = initialState, action) => {
  const { message, schoolEvents } = action.payload.result;
  return {
    ...state,
    loading: false,
    message,
    // events: groupBy(events, pro => pro.categories && pro.categories[0].name),
    events: schoolEvents
  };
};

const errorEvents = (state = initialState, action) => ({
  ...state,
  loading: false,
  error: action.error,
  events: null,
});



export default createReducer(initialState, {
  [Types.REQUEST_EVENTS]: requestEvents,
  [Types.SUCCESSFUL_EVENTS]: successfulEvents,
  [Types.ERROR_EVENTS]: errorEvents,

});