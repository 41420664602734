  
import { EventsActionCreators } from '../reducers/eventReducer';
import { SearchTextActionCreators} from '../reducers/searchTextReducer'
import {
	getEventsService,
} from '../services/events';
// import * as MessageActions from 'app/store/actions/fuse/message.actions';

export const getEvents = data => async dispatch => {
	dispatch(EventsActionCreators.requestEvents());
	try {
		const response = await getEventsService();
		dispatch(
			EventsActionCreators.successfulEvents({
				result: response
			})
		);
	} catch (e) {
		// dispatch(MessageActions.showMessage({ message: e.message, variant: 'error' }));
		dispatch(EventsActionCreators.errorEvents(e));
	}
};
